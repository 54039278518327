<template>
    <div>
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-12 col-lg-10 my-5">
          <h3>退換貨須知</h3>
          <div class="mt-5">
              <h5 class="text-primary ps-3">配送時間:</h5>
              <ul class="mt-3">
                  <li>訂單於營業日(週一至週五，國定例假日除外) 處理及配送。</li>
                  <li>國定例假日與周末之訂單，將於次營業日處理。</li>
                  <li>在您付款完成後約3~5個工作天內送達(不含週六，日及人事行政局公告之假日、另若遇特殊節日物流量過大或特殊情況則另行通知)。</li>
              </ul>
          </div>
          <div class="mt-5">
              <h5 class="text-primary ps-3">配送範圍:</h5>
              <p class="mt-3 ms-3">僅限於台灣(即台灣本島及澎湖、金門、馬祖)。若配送地址為離島，則依郵局實際作業時間而定。</p>
          </div>
          <div class="mt-5">
              <h5 class="text-primary ps-3">物流處理費:</h5>
              <p class="mt-3 ms-3"><span class="fw-bold">Papawash官網是全館免運費</span>(離島或偏遠地區選擇貨到付款，消費金額需滿2000元)，若有任何疑問請洽詢客服專線0809-080-038(週一至週五9:00-12:00，13:30-18:00(例假日/國定假日休息))或於官網<router-link to="/contactus" class="text-primary fw-bold text-decoration-underline">聯絡客服</router-link>聯絡我們。<br>
              *同一筆訂單之商品恕無法寄送到多個收件地址。若您需要將商品寄送多個收件地址，請您分別成立不同筆訂單。
              </p>
          </div>
          <div class="mt-5">
              <h5 class="text-primary ps-3">收貨:</h5>
              <p class="mt-3 ms-3">您訂購之商品配送至您指定的配送地址由您或您的代理人簽收完成後，請盡速檢查商品品項、數量是否正確以及商品是否有瑕疵。若您對於所收到商品有任何問題，您可透過客服專線0809-080-038(週一至週五9:00-12:00，13:30-18:00(例假日/國定假日休息))或於官網<router-link to="/contactus" class="text-primary fw-bold text-decoration-underline">聯絡客服</router-link>與我們連繫。
              </p>
          </div>
          <div class="mt-5">
              <h5 class="text-primary ps-3">七天鑑賞期內都可以退換貨嗎?</h5>
              <ul class="mt-3">
                  <li>依照消費者保護法的規定，Papawash消費者享有商品到貨後七天猶豫期的權益，但猶豫期並非試用期。</li>
                  <li>若需辦理退換貨時，您所退回的商品必須回復原狀（須回復至商品到貨時的原始狀態）並且保持完整包裝（包括商品本體、封膜、配件、贈品、內外包裝、原廠包裝...等完整性），切勿缺漏任何物件或損毀原廠外盒。</li>
                  <li>請您以送貨使用之原包裝紙箱將退換貨商品包裝妥當，若原紙箱已遺失，請另使用其他紙箱包覆於商品原廠包裝之外，切勿直接於原廠包裝上黏貼紙張或書寫文字。若欲退貨的商品，是組合出售的促銷商品，不可單品退貨，需將整組商品一起退回。</li>
                  <li>本公司安排指定的宅配公司收回完整全訂單商品與贈品、配件等，運費由本公司吸收，單次訂單僅限一次退貨或換貨。</li>
                  <li>若商品須辦理退換貨，請於上班時間致電本公司客服專線0809-080-038，以便安排退換貨或退款相關事宜。</li>
              </ul>
          </div>
          <div class="mt-5">
              <h5 class="text-primary ps-3">無法辦理退換貨之情形：</h5>
              <ul class="mt-3">
                  <li>若商品因消費者個人不當使用拆卸產生人為因素造成故障、毀損、磨損、擦傷、刮傷、髒污、包裝破損不完整者，或是搭贈贈品不全者，恕不接受退換貨。請先確認商品正確、外觀可接受，再行開啟/使用，以免影響您的權利。</li>
                  <li>本商品因衛生因素考量，除瑕疵品外，一經拆封使用皆無法接受退換貨。</li>
                  <li>商品封貼或原廠外盒包裝已經拆除受損、商品原廠外盒黏貼宅配單或書寫文字、商品缺件等情形時，恕無法退貨。</li>
                  <li>其他逾越檢查之必要或可歸責於您之事由，致商品有毀損、滅失或變更者。</li>
              </ul>
          </div>
          <div class="mt-5">
              <h5 class="text-primary ps-3">辦理退換貨之流程：</h5>
              <ol class="mt-3">
                  <li>請於商品到貨七天猶豫期內致電本公司客服專線0809-080-038。</li>
                  <li>客服人員接到通知三~五個工作日(國定例假日除外)將安排貨運到府收件。</li>
                  <li>收到換貨商品會先檢視商品狀態，若符合商品換貨規定將立即辦理換貨事宜。</li>
                  <li>收到退貨商品會先檢視商品狀態，若符合商品退貨規定將全額退款給您。</li>
              </ol>
          </div>
          <div class="mt-5">
              <h5 class="text-primary ps-3">其他：</h5>
              <ul class="mt-3">
                  <li>會員訂購後若大量退換貨，由於已造成作業上之困擾，Papawash 可能會視情況對該會員採取拒絕交易或永久取消其會員資格辦理。</li>
              </ul>
          </div>
          <div class="mt-5">
              <h5 class="text-primary ps-3">如對於本網站網路購物有任何問題，您可透過下列管道與我們聯繫：</h5>
              <p class="mt-3 ms-3">客服專線：0809-080-038<br>
                 服務時間：<span class="fw-bold">週一至週五9:00~12:00，13:30~18:00</span>(國定例假日除外)<br>
                 客服郵件信箱： <a class="text-primary fw-bold text-decoration-underline" href="mailto:papawash@uitc.com.tw">papawash@uitc.com.tw</a>
              </p>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<style lang="scss" scoped>
div.word {
    text-align: justify;
  }
div.contact {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  background-color: #ffff;
  // border-radius: 5px 5px 5px 5px;
  color: #000;
  border: 1px solid #adb5bd;
}
div.toptext {
  width: 100%;
  // border-radius: 5px 5px 0px 0px;
  background-color: #F0F0F2;
  color: #000;
}
div.boxes {
  @media (max-width: 768px){
       flex-direction: column;
    }
  @media (max-width: 576px){
       //
    }
}

</style>
